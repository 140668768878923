import { useDebugHotkey } from '../../../hooks/useDebugHotkey';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const QuizAnswerDebug = ({ response, correctAnswer }: { response?: any; correctAnswer: boolean | string }) => {
  const debugActive = useDebugHotkey();
  return (
    <div>
      {debugActive && response && (
        <div className={`text-sm ${response === correctAnswer ? 'text-green-500' : 'text-red-500'}`}>
          Correct answer: {correctAnswer}
          <br />
          Your answer: {response}
        </div>
      )}
    </div>
  );
};

export default QuizAnswerDebug;
