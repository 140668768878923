import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { Option } from '../../../Option';
import { ButtonGroup } from '../../../shared/form-control/ButtonGroup';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import { QuizActionData } from '../QuizActionEditorBase';
import QuizAnswerDebug from '../QuizAnswerDebug';

type TrueFalseProps = ActionBaseProps<Option<'true' | 'false', string>, QuizActionData>;

const TrueFalseAction: FC<TrueFalseProps> = (props) => {
  const { response, data, required } = props;
  const { onAnswer, readOnly } = useFormAction(props);
  const { t } = useTranslation('activity-type');

  const onClick = useCallback(
    (newValue: Option<string, string> | null) => {
      if (newValue?.id === response?.id) {
        newValue = null;
      }

      onAnswer(newValue as Option<'true' | 'false', string>);
    },
    [response?.id, onAnswer],
  );

  const buttons = useMemo(() => {
    const result = [
      { id: 'true', text: t('true-false.true'), value: 'true' },
      { id: 'false', text: t('true-false.false'), value: 'false' },
    ];

    return result;
  }, [t]);

  return (
    <div data-cy="true-false-action">
      <ActionTitleDescription required={required} {...data} />
      <div className="my-4">
        <ButtonGroup onClick={onClick} disabled={readOnly} selectedId={response?.id} buttons={buttons} />
      </div>
      <QuizAnswerDebug response={response?.id} correctAnswer={data.correctAnswer} />
    </div>
  );
};

export default TrueFalseAction;
